import cn from 'classnames';
import React from 'react';

interface InputProp {
    className?: string;
    children: any;
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset' | undefined;
}

function Button({ className = '', children, type = 'button', onClick }: InputProp) {
    return (
        <button type={type} className={cn('bg-orange-500 text-white px-4 py-2 text-sm font-bold rounded-md', className)} onClick={onClick}>
            {children}
        </button>
    );
}

export default Button;
