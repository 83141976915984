import Button from 'components/Button';
import Input from 'components/Input';
import React, { useCallback, useState } from 'react';
import Axios from 'axios';
import { Formik } from 'formik';

import { useParams } from 'react-router';
import FormValidation from 'utils/validationYup';

const ZAMPYLIFE_LOGO = require('assets/logo.png');

function RecoveryPassword() {
    const { key } = useParams<{ key: string }>();

    const [success, setSuccess] = useState<boolean>();
    const [error, setError] = useState<string>();

    const onFormSubmit = useCallback(
        async (values: any) => {
            try {
                setSuccess(undefined);
                setError(undefined);
                await Axios.post(process.env.REACT_APP_API_HOST + '/authentication/change-password', { ...values, key });
                setSuccess(true);
            } catch (err) {
                setSuccess(false);
                setError('Si è verificato un errore, riprova');
            }
        },
        [key],
    );

    return (
        <div className="h-screen w-screen p-5">
            <div className="container h-full -mt-20 flex flex-col justify-center items-center">
                <div className="w-full md:w-2/3 lg:w-1/2 rounded-md shadow-md p-5 flex flex-col items-center">
                    <div className="w-5/6 pb-5 flex flex-row justify-center items-start border-b">
                        <img className="w-40 h-auto" src={ZAMPYLIFE_LOGO} alt="" />
                    </div>
                    {!success ? (
                        <Formik initialValues={{ email: '', password: '', passwordConfirm: '', key }} validateOnMount={false} validationSchema={FormValidation} onSubmit={onFormSubmit}>
                            {form => (
                                <form
                                    className="w-full md:w-4/6 flex flex-col"
                                    onSubmit={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        form.handleSubmit();
                                    }}
                                >
                                    <p className="mt-5 text-xl text-center">Recupero password</p>

                                    <Input required type="email" className="mt-4" name="Email" value={form.values.email} onChange={t => form.setFieldValue('email', t.trim())} />
                                    <Input required type="password" className="mt-4" name="Nuova Password" value={form.values.password} onChange={t => form.setFieldValue('password', t)} />
                                    <Input
                                        required
                                        type="password"
                                        className="mt-4"
                                        name="Conferma Nuova Password"
                                        value={form.values.passwordConfirm}
                                        onChange={t => form.setFieldValue('passwordConfirm', t)}
                                    />
                                    <div className="flex flex-row justify-between items-center mt-4">
                                        <p className="text-red-500 text-sm" style={{ maxWidth: '50%' }}>
                                            {(error || Object.values(form.errors)[0]) ?? ''}
                                        </p>
                                        <Button type="submit" onClick={form.handleSubmit}>
                                            Modifica Password
                                        </Button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    ) : (
                        <div className="w-full md:w-4/6 flex flex-col items-center">
                            <p className="mt-5">La tua password è stata modificata con successo!</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default RecoveryPassword;
