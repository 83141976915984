import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './styles/index.css';
import './styles/globals.css';

import Recovery from 'pages/Recovery';

function App() {
    return (
        <Router>
            <Switch>
                <Route path="/recovery/:key" component={Recovery} />
            </Switch>
        </Router>
    );
}

export default App;
