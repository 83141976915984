import * as Yup from 'yup';

const FormValidation = Yup.object().shape({
    email: Yup.string().email('Inserire una email valida').required('Campo Email richiesto'),
    password: Yup.string().min(5, 'La password è troppo corta inserisci almeno 5 caratteri').required('Campo Nuova Password richiesto'),
    passwordConfirm: Yup.string()
        .required('Campo Conferma Nuova Password richiesto')
        .oneOf([Yup.ref('password')], 'La nuova password e la conferma della nuova password non corrispondono'),
});

export default FormValidation;
