import cn from 'classnames';
import React from 'react';

interface InputProp {
    className?: string;
    name?: string;
    value: string;
    type?: string;
    required?: boolean;
    onChange?: (t: string) => void;
}

function Input({ className = '', name, type = 'text', onChange, required = false }: InputProp) {
    return (
        <>
            <div className={cn('flex flex-col w-full', className)}>
                {!!name && <span className="text-sm text-gray-500 mb-1 ml-0.5">{name}</span>}
                <input
                    className="input rounded-md  border-orange-200 focus:border-orange-500 outline:none ring-orange-200 focus:ring-orange-500 text-sm"
                    type={type}
                    required={required}
                    onChange={e => onChange && onChange(e.target.value)}
                />
            </div>
        </>
    );
}

export default Input;
